import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BannerSimple from '../../../../components/BannerSimple';
import BackGlossary from '../../../../components/BackGlossary';

const ZspZeroStandingPrivilege = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `JIT`;
  const description = `Just In Time`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={`(${description})`} />
          </div>
        </section>

        <section className="pb-16 ">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <div className="md:px-5 max-w-[1285px]">
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Just In Time (JIT)</div>
                <p className="text-lg text-left">
                  Just-In-Time Access: Applying Lean Principles to Cloud Computing Security Just-in-time (JIT) access is
                  a concept that has gained traction in cloud computing security circles in recent years. JIT access
                  involves granting users access to cloud environments only when they need it, and revoking that access
                  as soon as it's no longer needed. This approach to cloud access control is based on the principles of
                  just-in-time manufacturing, which emphasizes efficiency and waste reduction by producing only what is
                  needed, when it is needed. Since each cloud environment has its own nuances and criteria, it’s been a
                  challenge for companies to implement JIT easily.
                </p>
                <br></br>
                <p className="text-lg text-left">
                  Let’s explore the benefits of JIT access and temporary access, as well as the problems that it solves
                  for security teams and development teams. We'll also introduce a context based access management
                  solution that can help organizations implement JIT access controls with ease.{' '}
                </p>
                <br></br>
                <p className="text-lg text-left">
                  <b>Problems Solved by Just-In-Time Access:</b>
                  <br></br>
                  <br></br> JIT access solves a range of problems that security and development teams face in modern
                  cloud environments. Here are some of the key problems that JIT access addresses: <br></br>
                </p>
                <br></br>
                <ul className="mb-5 ml-12 text-lg list-disc">
                  <li className="mb-5">
                    <b>Reduced Attack Surface:</b> With JIT access controls in place, the attack surface of your cloud
                    environment is minimized, as users are granted access only when they need it, and for a limited
                    period of time.
                  </li>
                  <li className="mb-5">
                    <b>Better Compliance:</b> JIT access makes it easier to track who has accessed your cloud
                    environment and when, which is crucial for compliance with regulations like GDPR and HIPAA.
                  </li>
                  <li className="mb-5">
                    <b>Improved Efficiency:</b> JIT access eliminates the need for manual access provisioning, which can
                    be time-consuming and error-prone.
                  </li>
                  <li className="mb-5">
                    <b>Enhanced Security:</b> JIT access minimizes the risk of security breaches, as users only have
                    access to the resources they need, and for a limited period of time.
                  </li>
                </ul>

                <br></br>
                <p className="text-lg text-left">
                  The Benefits of Just-In-Time and Temporary Access are substantial when thinking about securing cloud
                  environments for larger companies. JIT access provides a number of benefits that are critical for
                  cloud security, including: <br></br>
                  <br></br>
                </p>
                <ul className="mb-5 ml-12 text-lg list-disc ">
                  <li className="mb-5">
                    <b>Reduced Risk:</b> With JIT access controls in place, you can reduce the risk of unauthorized
                    access to your cloud environment, as well as data breaches and cyber attacks.
                  </li>
                  <li className="mb-5">
                    <b>Better Visibility:</b>JIT access allows you to track who has accessed your cloud environment and
                    when, providing greater visibility into your security posture.{' '}
                  </li>
                  <li className="mb-5">
                    <b>Increased Efficiency:</b> JIT access eliminates the need for manual access provisioning, which
                    can be time-consuming and prone to errors.
                  </li>
                </ul>

                <br></br>
                <p className="text-lg text-left">
                  Temporary access is a critical component of JIT access controls. By granting access to cloud resources
                  for a limited period of time, and automatically revoking access when the time limit expires, temporary
                  access provides several benefits. It increases security by reducing the risk of unauthorized access,
                  gives you greater control by allowing you to define specific time limits, and improves compliance by
                  providing a clear audit trail of access to your cloud environment. With these benefits, temporary
                  access is a key element in securing your cloud systems and ensuring compliance with regulations.
                </p>
                <br></br>
                <p className="text-lg text-left">
                  In today's fast paced, high tech business environment, cloud security is a top priority. Just-in-time
                  access controls provide a solution that minimizes the attack surface, enhances compliance, and
                  increases efficiency.
                </p>
                <br></br>
                <p className="text-lg text-left">
                  By implementing JIT access controls, you can secure your cloud environments, including AWS, Github,
                  and Azure, and minimize the risk of unauthorized access. Trustle makes implementing JIT access a light
                  lift for your organization. Once Trustle is set up, the system continues to learn and can help to make
                  automatic decisions based on context like the user trust score, or the sensitivity of the resources.
                </p>
                <br></br>
                <p className="text-lg text-left">
                  So, don't wait any longer. Take the first step towards securing your cloud systems with just-in-time
                  access controls. Start implementing JIT access today and enjoy the benefits of a more secure,
                  compliant, and efficient cloud environment.
                </p>
              </div>
            </div>
          </div>
          <BannerSimple
            title="Curious how Trustle works?"
            description={'Get an inside look at Trustle. Schedule a demo with us today.'}
          />
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default ZspZeroStandingPrivilege;
